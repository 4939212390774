var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"md:h-full mb-20"},[_c('section',{staticClass:"border border-blue-200 duration-300 md:flex md:h-full"},[_c('div',{staticClass:"md:w-4/12 xl:w-3/10 overflow-y-auto"},[_c('div',{staticClass:"font-medium px-8 py-5 text-gray-700"},[_vm._v(" All Companies ")]),_c('datatable',{ref:"companies",attrs:{"url":_vm.companies.url,"ajax":true,"ajaxPagination":true,"columns":_vm.companies.columns,"filters":_vm.companies.filters,"query":_vm.companies.query,"className":_vm.tableRowClassName,"fillable":true,"dropdown":false,"on-click":_vm.selectCompany},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"pt-4"},[_c('search-form',{staticClass:"mb-3",attrs:{"placeholder":"Search companies"},on:{"submit":function($event){return _vm.reloadTable('companies', true)}},model:{value:(_vm.companies.query),callback:function ($$v) {_vm.$set(_vm.companies, "query", $$v)},expression:"companies.query"}})],1),_c('button',{staticClass:"btn btn-sm btn-gray mb-3",attrs:{"type":"button"},on:{"click":_vm.toggleLetterFilters}},[_vm._v(" "+_vm._s(_vm.showLetterFilters ? 'Hide' : 'Show')+" Filters ")]),(_vm.showLetterFilters)?[_c('div',{staticClass:"flex -mx-1 flex-wrap"},[_vm._l((_vm.numberOptions),function(filter,index){return [_c('button',{key:index,staticClass:"border border-blue-200 hover:border-blue-500 font-medium mb-2 mx-1 py-1 px-3 rounded-sm text-sm uppercase",class:{
                    'bg-blue-500 border-blue-500 text-white': _vm.currentFilter === filter,
                    'text-gray-600 hover:text-blue-500': _vm.currentFilter !== filter
                  },attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.toggleFilter(filter)}}},[_vm._v(" "+_vm._s(filter.name)+" ")])]})],2),_c('div',{staticClass:"border-t border-blue-200 flex flex-wrap mt-2 -mx-1 pt-4"},[_vm._l((_vm.alphabetOptions),function(filter,index){return [_c('button',{key:index,staticClass:"border border-blue-200 hover:border-blue-500 font-medium mb-2 mx-1 py-1 px-3 rounded-sm text-sm uppercase",class:{
                    'bg-blue-500 border-blue-500 text-white': _vm.currentFilter === filter,
                    'text-gray-600 hover:text-blue-500': _vm.currentFilter !== filter
                  },attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.toggleFilter(filter)}}},[_vm._v(" "+_vm._s(filter.name)+" ")])]})],2)]:_vm._e()]},proxy:true}])})],1),_c('div',{staticClass:"border-l border-blue-200 flex-grow overflow-y-auto"},[(_vm.companies.selected)?[_c('div',{staticClass:"font-medium px-8 py-5 text-gray-700"},[_vm._v(" "+_vm._s(_vm._f("sentenceCase")(_vm.companies.selected && _vm.companies.selected.name))+" ")]),_c('datatable',{ref:"users",attrs:{"url":_vm.users.url,"ajax":true,"ajaxPagination":true,"columns":_vm.users.columns,"filters":_vm.users.filters,"query":_vm.users.query,"fillable":true,"dropdown":false,"on-click":_vm.click,"sort":"salary","order":"desc"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"pt-4"},[_c('search-form',{staticClass:"mb-5",attrs:{"placeholder":"Search users names, emails, phone numbers, bvn, etc."},on:{"submit":function($event){return _vm.reloadTable('users')}},model:{value:(_vm.users.query),callback:function ($$v) {_vm.$set(_vm.users, "query", $$v)},expression:"users.query"}})],1)]},proxy:true},{key:"td-3",fn:function({ item: { row } }){return [(_vm.isActive(row))?_c('span',{staticClass:"badge badge-green"},[_vm._v("Active")]):_c('span',{staticClass:"badge badge-orange"},[_vm._v("Inactive")])]}}],null,false,238798585)})]:[_vm._m(0)]],2)]),_c('modals-user',{ref:"userModal",attrs:{"selected-user":_vm.users.selected},on:{"delete":_vm.userDeleted,"close":_vm.userModalClosed}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex h-full items-center justify-center p-10 text-center"},[_c('h4',{staticClass:"text-lg font-medium text-gray-700"},[_c('span',{staticClass:"mr-2"},[_vm._v("Click on a company")]),_c('span',{staticClass:"md:hidden mr-2"},[_vm._v("above")]),_c('span',{staticClass:"hidden md:inline mr-2"},[_vm._v("on the left")]),_c('span',[_vm._v("to view their users")])])])
}]

export { render, staticRenderFns }